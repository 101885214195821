<template>
  <div style="height: 100%">
    <v-navigation-drawer
      v-model="drawer"
      right
      permanent
      absolute
      :width="drawerWidth"
      :mini-variant="!isExpanded"
      class="chat-drawer"
      @transitionend="handleTransitionEnd"
      style="
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        height: 100%;
      "
    >
      <v-card
        flat
        :loading="loadingComments"
        class="chat-card"
        :class="{ 'chat-card-expanded': isExpanded }"
        style="background: transparent !important"
      >
        <v-card-title @click="toggleDrawer" class="cursor-pointer">
          <v-tooltip>
            <template v-slot:activator="{ on }">
              <v-icon left color="grey" v-on="on">chat</v-icon>
            </template>
            <span>Shipment Comments</span>
          </v-tooltip>
          <span v-if="isExpanded">
            Comments
            <v-spacer></v-spacer>
          </span>

          <v-icon v-if="isExpanded">{{
            isExpanded ? "chevron_right" : "chevron_left"
          }}</v-icon>
        </v-card-title>

        <v-card-text v-show="isExpanded" class="chat-content ma-0 pa-0">
          <v-list ref="commentList" dense class="chat-list ma-0 pa-0">
            <v-list-item v-if="comments.length === 0" class="text-center">
              <v-list-item-content>
                <span style="font-size: 12px;color: grey">No comments.</span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(comment, index) in comments"
              :key="comment.id"
              :id="`comment_${index}`"
            >
              <v-list-item-action class="mr-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      v-on="on"
                      :color="!comment.user.avatar ? 'secondary' : 'white'"
                      size="36"
                    >
                      <h3 v-if="!comment.user.avatar">
                        {{ user.comment.firstname.charAt(0) }}
                      </h3>
                      <v-img v-else :src="comment.user.avatar" contain />
                    </v-avatar>
                  </template>
                  <span class="text-caption">{{
                    `${comment.user.firstname} ${comment.user.surname}`
                  }}</span>
                </v-tooltip>
              </v-list-item-action>

              <v-list-item-content
              >
                <v-list-item-title
                  class="text-wrap"
                  :class="{
                    'text-decoration-line-through grey--text':
                      comment.isDeleted,
                  }"
                >
                  {{ comment.comment }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-caption grey--text font-italic"
                >
                  {{ $Format.formatDate(comment.createdAt).dateTimeDefault }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action
                v-if="
                  comment.userId === $store.state.user.id && !comment.isDeleted
                "
              >
              <v-row justify="center">
                <v-btn icon small @click="openShipmentDialog(comment)">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  :loading="loadingShipmentDeleteComment"
                  @click="deleteShipmentComment(comment.id)"
                >
                  <v-icon color="red" small>delete</v-icon>
                </v-btn>
              </v-row>
               
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions v-show="isExpanded" class="chat-input">
          <v-text-field
            v-model="comment"
            :loading="savingComment"
            :disabled="savingComment"
            outlined
            dense
            hide-details
            placeholder="Type a message..."
            append-outer-icon="send"
            @keyup.enter="createComment"
            @click:append-outer="createComment"
          ></v-text-field>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <v-dialog v-model="editButton" max-width="500px">
      <v-card v-if="newEditedComment">
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px">
            Edit Comment
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingShipmentEditComment"
            icon
            color="primary"
            @click="updateContainerComment(newEditedComment.id)"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="editButton = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <v-textarea
            v-model="newEditedComment.comment"
            style="width: 80%; contain: content; border-radius: 20px"
            outlined
            rows="4"
          >
          </v-textarea>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  name: "ChatDrawer",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drawer: false,
      editButton: false,
      isExpanded: false,
      comments: [],
      comment: "",
      loadingComments: false,
      miniVariant: true,
      newEditedComment: null,
      savingComment: false,
      loadingShipmentEditComment: false,
      loadingShipmentDeleteComment: false,
    };
  },
  watch: {
    "shipment.id": {
      immediate: true,
      handler(val) {
        if (val) this.getComments(this.shipment.id);
      },
    },
  },
  computed: {
    drawerWidth() {
      return this.isExpanded ? 400 : 48;
    },
  },
  methods: {
    async deleteShipmentComment(id) {
      this.loadingShipmentDeleteComment = true;
      let result = await this.$API.deleteShipmentComment({
        id: id,
        isDeleted: true,
        isActive: false,
        shipmentId: this.shipment.id,
      });
      if (result) {
        this.loadingShipmentDeleteComment = false;
        this.getComments(this.shipment.id);
      }
    },
    toggleDrawer() {
      this.isExpanded = !this.isExpanded;
      if (!this.drawer) this.drawer = true;
    },
    async getComments(id) {
      this.loadingComments = true;
      this.comments = await this.$API.getShipmentComments(id);
    //   this.documentComments = await this.$API.getShipmentDocumentComments(id);
      this.scrollToEnd();
      this.loadingComments = false;
    },
    handleTransitionEnd() {
      if (!this.isExpanded && this.drawer) this.drawer = false;
    },
    getAvatarInitials(user) {
      return `${user.firstname[0]}${user.surname[0]}`.toUpperCase();
    },
    async createComment() {
      this.comment = this.comment.trim();
      if (this.comment != "") {
        this.savingComment = true;
        let obj = {
          comment: this.comment,
          userId: this.$store.state.user.id,
          shipmentId: this.shipment.id,
          isEdited: false,
        };
        let newComment = await this.$API.createShipmentComment(obj);
        this.comments.push(newComment);
        this.comment = "";
        this.savingComment = false;
        this.scrollToEnd();
      } else {
        this.$message.error("Can't create empty comment.");
      }
    },

    openShipmentDialog(comment) {
      this.loadingShipmentEditComment = false;
      this.newEditedComment = comment;
      this.editButton = true;
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var list = this.$refs.commentList;
        list.$el.scrollTop = list.$el.scrollHeight;
      });
    },
    async updateContainerComment(id) {
      this.loadingShipmentEditComment = true;
      let result = await this.$API.updateShipmentComment({
        id: id,
        isDeleted: true,
        isActive: false,
        shipmentId: this.shipment.id,
        userId: this.newEditedComment.userId,
        comment: this.newEditedComment.comment,
      });
      if (result) {
        this.editButton = false;
        this.getComments(this.shipment.id);
        this.loadingShipmentEditComment = false;
      }
    },
  },
};
</script>
  
  <style scoped>
.chat-drawer {
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.chat-card {
  display: flex;
  flex-direction: column;
  height: 95%;
  transition: all 0.3s ease;
}

.chat-card-expanded {
  margin: 8px;
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.chat-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-list {
  flex-grow: 1;
  overflow-y: auto;
}

.chat-input {
  padding: 8px;
}
</style>